import algosdk from 'algosdk';
import { sdkClient } from './util';


function getAssetIndex () {
  // MainNet USDC
  return 31566704;
}

export async function getScenario (scenarioId, address, chain) {
  switch ( scenarioId ) {
    case 1:
      return await singlePayTxn(address, chain);
    case 2:
      return await singleAssetTransferTxn(address, chain);
    case 3:
      return await singleAssetOptInTransferTxn(address, chain);
    default:
      return singlePayTxn(address, chain);
  }

}

export async function apiGetTxnParams (chain) {
  return await sdkClient(chain).getTransactionParams().do();
}

export async function singlePayTxn (address, chain) {
  const params      = await apiGetTxnParams(chain);
  const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject(
    {
      sender: address,
      receiver: address,
      amount: 100000,
      note: Uint8Array.from(Buffer.from('example note value')),
      suggestedParams: params
    }
  );

  return [{txn: transaction}];
}


export async function singleAssetTransferTxn (address, chain) {
  const params      = await apiGetTxnParams(chain);
  const assetIndex = getAssetIndex();

  const transaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    sender: address,
    receiver: address,
    amount: 100000,
    assetIndex,
    note: Uint8Array.from(Buffer.from('example note value')),
    suggestedParams: params
  });

  return [{txn: transaction}];
}



export async function singleAssetOptInTransferTxn (address, chain) {
  const params      = await apiGetTxnParams(chain);
  const assetIndex = getAssetIndex();

  const transaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    sender: address,
    receiver: address,
    amount: 0,
    assetIndex,
    note: Uint8Array.from(Buffer.from('example note value')),
    suggestedParams: params
  });

  return [{txn: transaction}];
}
